/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

const $ = require('jquery');
require('bootstrap');
require('webpack-icons-installer');

require('js-cookie');

import 'bootstrap/dist/css/bootstrap.min.css';

// any CSS you require will output into a single css file (app.css in this case)
require('../scss/style.scss');

console.log('Hello Webpack Encore! Edit me in assets/js/app.js');

$('document').ready(function(){
    var popout = false;

    setTimeout(function() {
        if(Cookies.get('training-im')) {
            // if cookie is set
        } else {
            if(popout == false) {
                $('#training-im').fadeIn('fast', function(){
                    Cookies.set("training-im", 1, { expires : 7 });
                    popout = true;
                });
            }
        }
    }, 3000);

    $('.popout-close').on('click', function () {
        $('.popout-backdrop').fadeOut('fast');
        popout = false;
    })

    $('.btn-close').on('click', function () {
        $('.popout-backdrop').fadeOut('fast');
        setTimeout(function() {
            popout = false;
        }, 3000);
    })

    $('.popout-backdrop').on('click', function () {
        $('.popout-backdrop').fadeOut('fast');
        popout = false;
    })

    $('.popout').on('click', function (event) {
        event.stopPropagation();
    })

    $('html').mouseleave(function () {
        if(Cookies.get('publication-im')) {
            // if cookie is set
        } else {
            if(popout == false) {
                $('#publication-im').fadeIn('fast', function(){
                    Cookies.set("publication-im", 1, { expires : 7 });
                    popout = true;
                });
            }
        }
    });
});